<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="container">
      <div id="logo" class="navbar-brand">
        <nuxt-link
          to="/"
        >
          <img src="@/assets/paccurate_final.svg" alt="Paccurate">
        </nuxt-link>
        <span
          id="nav-toggle"
          :class="{ 'is-active': showNav }"
          role="button"
          class="navbar-burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="navMain"
          @click="showNav = !showNav"
        >
          <span />
          <span />
          <span />
        </span>
      </div>
      <div id="navMain" :class="{ 'is-active': showNav }" class="navbar-menu">
        <div class="navbar-end">
          <nuxt-link to="/blog" class="navbar-item" exact>
            Blog
          </nuxt-link>
          <a href="https://docs.paccurate.io/" class="navbar-item">
            Docs
          </a>
          <nuxt-link to="/pricing" class="navbar-item" exact>
            Pricing
          </nuxt-link>
          <nuxt-link to="/partners" class="navbar-item" exact>
            Partners
          </nuxt-link>
          <div class="buttons">
            <a href="https://manage.paccurate.io/sign_up" class="navbar-item button is-primary">Grab an API Key</a>
            <a href="https://manage.paccurate.io" class="navbar-item button is-light">Log In</a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  data () {
    return {
      showNav: false
    }
  },
  watch: {
    '$route' () {
      this.showNav = false
    }
  }
}
</script>
