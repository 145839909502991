<template>
  <div>
    <div class="container has-text-centered" style="padding: 5em 0em;">
      <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" fill="#DBE1EC" viewBox="0 0 48 48">
        <path d="M22 30h4v4h-4zm0-16h4v12h-4zm1.99-10C12.94 4 4 12.95 4 24s8.94 20 19.99 20S44 35.05 44 24 35.04 4 23.99 4zM24 40c-8.84 0-16-7.16-16-16S15.16 8 24 8s16 7.16 16 16-7.16 16-16 16z" />
      </svg>
      <h1 class="bigTitle">Oops.</h1>
      <div class="title">
        We couldn't find a carton for that... <br> {{ message }}
      </div>
      <p v-if="statusCode === 404">
        <a href="/">
          Return to homepage
        </a>
        <br><br><br><br>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NuxtError',
  props: {
    error: { // <--- Send in the error
      type: Object,
      default: null
    }
  },
  head () {
    return {
      title: this.message // <--- Set the SEO title
    }
  },
  computed: {
    statusCode () { // <--- Get the status code
      return (this.error && this.error.statusCode) || 500
    },
    message () { // <--- Print the error
      return this.error.message
    }
  }
}
</script>
