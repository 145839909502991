import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _80a9dc6c = () => interopDefault(import('../pages/blog.vue' /* webpackChunkName: "pages/blog" */))
const _31c36c28 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _705dab5a = () => interopDefault(import('../pages/partners.vue' /* webpackChunkName: "pages/partners" */))
const _e79ab664 = () => interopDefault(import('../pages/pricing.vue' /* webpackChunkName: "pages/pricing" */))
const _ee2110e0 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _10ade96d = () => interopDefault(import('../pages/terms-of-service.vue' /* webpackChunkName: "pages/terms-of-service" */))
const _d58e2158 = () => interopDefault(import('../pages/category/_tag.vue' /* webpackChunkName: "pages/category/_tag" */))
const _47a289c6 = () => interopDefault(import('../pages/posts/_title_slug.vue' /* webpackChunkName: "pages/posts/_title_slug" */))
const _6407384c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/blog",
    component: _80a9dc6c,
    name: "blog"
  }, {
    path: "/contact",
    component: _31c36c28,
    name: "contact"
  }, {
    path: "/partners",
    component: _705dab5a,
    name: "partners"
  }, {
    path: "/pricing",
    component: _e79ab664,
    name: "pricing"
  }, {
    path: "/privacy",
    component: _ee2110e0,
    name: "privacy"
  }, {
    path: "/terms-of-service",
    component: _10ade96d,
    name: "terms-of-service"
  }, {
    path: "/category/:tag?",
    component: _d58e2158,
    name: "category-tag"
  }, {
    path: "/posts/:title_slug?",
    component: _47a289c6,
    name: "posts-title_slug"
  }, {
    path: "/",
    component: _6407384c,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
