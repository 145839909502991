<template>
  <div id="app">
    <nav-bar />
    <nuxt keep-alive />
    <the-shoe />
    <modal />
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import axios from 'axios'
import NavBar from '~/components/NavBar.vue'
import TheShoe from '~/components/TheShoe.vue'
import Modal from '~/components/Modal.vue'

export default {
  components: {
    NavBar,
    TheShoe,
    Modal
  },
  head () { // <-- property used by vue-meta to add header tags
    return {
      titleTemplate: '%s - Paccurate' // <-- For our title tag
      // script: [
      //   { hid: 'hs-script-loader', src: '//js.hs-scripts.com/702696.js', defer: true }
      // ],
      // meta: [
      //   {
      //     hid: 'description',
      //     name: 'description', // <-- for our meta description tag
      //     content:
      //       'Smarter Cartonization'
      //   }
      // ]
      // bodyAttrs: {
      //   class: 'has-navbar-fixed-top'
      // }
    }
  },
  computed: {
    ...mapState(['posts'])
  }
}
</script>

<style>

</style>
