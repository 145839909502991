// Need to refactor to make sure we aren't directly mutating objects returned by vuex state
// export const strict = true

export const state = () => ({
  itemsPackedNum: '',
  itemsPackedSpeed: '',
  itemsPackedVol: '',
  playLine: true,
  posts: []
})

export const mutations = {
  setPosts (state, posts) {
    state.posts = posts
  },
  setPackedParams (state, payload) {
    state.itemsPackedNum = payload.itemsPackedNum
    state.itemsPackedSpeed = payload.itemsPackedSpeed
    state.itemsPackedNum = payload.itemsPackedVol
  },
  setPlayLine (state, payload) {
    state.playline = payload
  }
}

export const actions = {
  nuxtServerInit ({ commit }) {
    if (process.server) {
      commit('setPosts', process.env.staticPosts)
    }
  }
}
// export const mutations = {
//   calcCost (state) {
//     // state.estPrice = state.ordervol * 0.5
//     // let price = state.ordervol * 0.5
//     // let val = (value/1).toFixed(2).replace('.', ',')
//     // state.estPrice = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
//   },
//   updateMonthlyShipSpend (state, monthlyShipSpend) {
//     state.monthlyShipSpend = monthlyShipSpend
//   }
// }

// export const actions = {
//   updateCost (context) {
//     context.commit('calcCost')
//   }
// }
